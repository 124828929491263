import { Helmet } from "react-helmet";
import React, { ReactNode } from "react";
import { makeStyles, Toolbar } from "@material-ui/core";

import { AppBar } from "./AppBar";
import { AppSidebar } from "./AppSidebar";
import { useScrollMemory } from "../../hooks/useScrollMemory";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flex: "1 1 100%" },
  contentContainer: {
    display: "flex",
    flex: "1 1 100%",
    maxWidth: "100%",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.typography.pxToRem(32),
    [theme.breakpoints.only("xs")]: {
      padding: theme.typography.pxToRem(16),
    },
  },
}));

interface Props {
  readonly pageTitle?: string;

  readonly children: ReactNode;
  readonly actions?: ReactNode;
}

export function AppLayout({ children, actions, pageTitle }: Props) {
  const classes = useStyles();

  useScrollMemory();

  return (
    <div className={classes.root}>
      {Boolean(pageTitle) && (
        <Helmet>
          <title>Midlo - {pageTitle}</title>
        </Helmet>
      )}

      <AppBar>{actions}</AppBar>

      <AppSidebar />

      <main className={classes.contentContainer}>
        <Toolbar />
        <section className={classes.content}>{children}</section>
      </main>
    </div>
  );
}
