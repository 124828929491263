import qs from "qs";
import { pathParams } from "path-params";
import ky, { Hooks, Options as KYOptions, ResponsePromise } from "ky";

import { TokenProps } from "./auth/AuthDTO";
import { AppError } from "../utils/AppError";
import { ResponseErrorDataProps } from "./AppDTO";
import { IS_DEV } from "../constants/AppConstants";
import { apiLogger, getPaginationFromHeaders } from "../utils/ApiUtils";
import { resetAuthToken } from "../reducers/AuthReducer";

export interface ApiProps {
  readonly host: string;
  readonly token?: TokenProps;
  readonly languageCode?: string;
  readonly onUpdateToken?: () => void;
}

export interface ResponseListPaginationProps {
  readonly page: number;
  readonly perPage: number;
  readonly pageCount: number;
  readonly totalCount: number;
}

export interface ResponseListProps<TData = any> extends ResponseListPaginationProps {
  readonly list: TData[];
}

export interface Options extends KYOptions {
  readonly query?: object;
  readonly params?: object;
}

let store;

export function injectStore(mainStore) {
  store = mainStore
}

export class BaseApi {
  private readonly host: string;
  private readonly token?: string;

  constructor({ token, host }: ApiProps) {
    this.host = host;
    this.token = token?.token;
  }

  private queryToString(query: object = {}): string {
    return qs.stringify(query);
  }

  private createRequestUrl(url: string, query: object = {}, params: object = {}): string {
    const formattedUrl = pathParams(url, params);

    return [formattedUrl, this.queryToString(query)].filter(Boolean).join("?");
  }

  private createRequestOptions(options: KYOptions): KYOptions {
    const { hooks = {} as Hooks } = options;

    const headers = new Headers(options.headers as Headers);

    if (this.token) {
      headers.set("Authorization", `Bearer ${this.token}`);
    }

    return {
      prefixUrl: this.host,
      ...options,
      headers,
      hooks: {
        ...hooks,
        beforeRequest: [...(hooks?.beforeRequest || []), apiLogger],
      },
      timeout: IS_DEV && !options.timeout ? false : options.timeout || 20000,
    };
  }

  private request(url: string, options: Options = {}): ResponsePromise {
    const { query, params, ...kyOptions } = options;

    const formattedOptions = this.createRequestOptions(kyOptions);
    const formattedUrl = this.createRequestUrl(url, query, params);

    return ky(formattedUrl, formattedOptions);
  }

  private jsonRequest<TData>(url: string, options?: Options): Promise<TData> {
    return new Promise<TData>((resolve, reject) => {
      this.request(url, options)
        .then((response) => {
          if (response.ok) {
            if (response.status === 204) {
              return response;
            }

            return response.json();
          }

          return response
            .json()
            .then((data) => this.parseError(response.status, data))
            .then((error) => {
              throw error;
            });
        })
        .then((data) => {
          if (data.status === 204) {
            return resolve({} as TData);
          }

          return resolve(data);
        })
        .catch((error) => {
          if (error instanceof AppError) {
            reject(error);
          } else if (error.name === "TimeoutError") {
            reject(
              this.parseError(500, {
                title: "Timeout",
                detail: "The connection timed out, please try again later.",
                violations: [],
              }),
            );
          } else {
            if (error?.response?.status === 401) return store.dispatch(resetAuthToken());
            error?.response
              ?.json()
              .then((data: ResponseErrorDataProps) => reject(this.parseError(error.response.status, data)));
          }
        });
    });
  }

  private parseError(statusCode: number, response: ResponseErrorDataProps): AppError {
    const error = new AppError(response.detail || response.title);

    error.status = statusCode;
    error.data = response.violations || [];

    return error;
  }

  public get<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "get" });
  }

  public post<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "post" });
  }

  public put<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "put" });
  }

  public delete<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "delete" });
  }

  public getList<TData = any>(url: string, options?: Options): Promise<ResponseListProps<TData>> {
    return new Promise((resolve, reject) =>
      this.request(url, options)
        .then((response) => {
          if (response.ok) {
            return response.json().then((data) => {
              const pagination = getPaginationFromHeaders(response.headers);

              return {
                list: data,
                page: pagination.page,
                perPage: pagination.perPage,
                pageCount: pagination.pageCount,
                totalCount: pagination.totalCount,
              };
            });
          }

          return response
            .json()
            .then((data) => this.parseError(response.status, data))
            .then((error) => {
              throw error;
            });
        })
        .then(resolve)
        .catch((error) => {
          if (error instanceof AppError) {
            reject(error);
          } else if (error.name === "TimeoutError") {
            reject(
              this.parseError(500, {
                title: "Timeout",
                detail: "The connection timed out, please try again later.",
                violations: [],
              }),
            );
          } else {
            if (error?.response?.status === 401) return store.dispatch(resetAuthToken());
            error?.response
              ?.json()
              .then((data: Response) => reject(this.parseError(error.response.status, data as any)));
          }
        }),
    );
  }
}
