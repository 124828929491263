import React from "react";
import { List } from "@material-ui/core";

import { ShopIcon } from "../icons/ShopIcon";
import { NewsIcon } from "../icons/NewsIcon";
import { BonusIcon } from "../icons/BonusIcon";
import { Routes } from "../../constants/Routes";
import { ClientsIcon } from "../icons/ClientsIcon";
import { EmployeeIcon } from "../icons/EmployeeIcon";
import { AnalyticsIcon } from "../icons/AnalyticsIcon";
import { TransactionIcon } from "../icons/TransactionIcon";
import { AppMenuItemWrapper } from "./AppMenuItemWrapper";

export function AppMenu() {
  return (
    <List component="nav">
      <AppMenuItemWrapper title="Analytics" to={Routes.Analytics} leftIcon={<AnalyticsIcon />} />
      <AppMenuItemWrapper title="Venues" to={Routes.Shops} leftIcon={<ShopIcon />} />
      <AppMenuItemWrapper
        leftIcon={<BonusIcon />}
        to={Routes.LoyaltyPrograms}
        title="Loyalty programs"
      />
      <AppMenuItemWrapper title="Staff" to={Routes.Employees} leftIcon={<EmployeeIcon />} />
      <AppMenuItemWrapper title="Customers" to={Routes.Clients} leftIcon={<ClientsIcon />} />
      <AppMenuItemWrapper
        title="Purchases"
        to={Routes.Transactions}
        leftIcon={<TransactionIcon />}
      />
      <AppMenuItemWrapper title="News" to={Routes.News} leftIcon={<NewsIcon />} />
      {/*<AppMenuItemWrapper title="Promotions" to={Routes.Promotions} leftIcon={<PromotionIcon />} />
      <AppMenuItemWrapper title="Documents" to={Routes.Documents} leftIcon={<DocumentIcon />} />*/}
    </List>
  );
}
