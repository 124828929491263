import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";

import { AppUserButton } from "./AppUserButton";
import { ActionMenuItem } from "../ui/ActionMenuItem";
import { userSelector } from "../../reducers/UserReducer";
import { ColorPalette } from "../../constants/ColorPalette";
import { useAuthContext } from "../../api/auth/AuthContext";
import { useShallowEqualSelector } from "../../hooks/useShallowEqualSelector";

const useStyle = makeStyles((theme) => ({
  paper: {
    borderRadius: theme.typography.pxToRem(4),
    boxShadow:
      "0px 16px 48px rgba(52, 65, 89, 0.04), 0px 0px 24px rgba(92, 112, 148, 0.08), 0px 4px 4px rgba(63, 80, 109, 0.04)",
  },
  list: { padding: 0 },
}));

export function AppUserMenuWrapper() {
  const classes = useStyle();

  const user = useShallowEqualSelector(userSelector);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const { logout } = useAuthContext();

  const show = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const closeHandler = useCallback(() => setAnchorEl(null), []);

  if (!user) {
    return null;
  }

  return (
    <div>
      <AppUserButton
        user={user}
        aria-label="more"
        aria-haspopup="true"
        aria-controls="user-menu"
        onClick={(event) => setAnchorEl(event.currentTarget as any)}
      />
      <Menu
        open={show}
        id="user-menu"
        classes={classes}
        keepMounted={true}
        anchorEl={anchorEl}
        onClose={closeHandler}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ActionMenuItem onClick={() => closeHandler()}>Edit</ActionMenuItem>
        <ActionMenuItem
          onClick={() => {
            logout();

            closeHandler();
          }}
          color={ColorPalette.Error}
        >
          Logout
        </ActionMenuItem>
      </Menu>
    </div>
  );
}
