import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar as MUIAppBar, Toolbar, Typography, useMediaQuery } from "@material-ui/core";

import { DotIcon } from "../icons/DotIcon";
import { AppUserMenuWrapper } from "./AppUserMenuWrapper";
import { ColorPalette } from "../../constants/ColorPalette";
import { AppMenuButtonWrapper } from "./AppMenuButtonWrapper";

const useStyles = makeStyles((theme) => ({
  dot: { marginRight: theme.typography.pxToRem(10) },
  actionsContainer: {
    flex: 1,
    paddingLeft: theme.typography.pxToRem(8),
    [theme.breakpoints.only("xs")]: {
      paddingLeft: 0,
    },
  },
  title: { fontSize: theme.typography.pxToRem(16), lineHeight: theme.typography.pxToRem(24) },
  actionToolbar: {
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${ColorPalette.Gray}`,
    [theme.breakpoints.only("xs")]: {
      paddingLeft: theme.typography.pxToRem(4),
      paddingRight: theme.typography.pxToRem(4),
    },
  },
  appBar: {
    boxShadow: "none",
    flexDirection: "row",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: ColorPalette.White,
  },
  titleToolbar: {
    backgroundColor: ColorPalette.Primary,
    width: theme.typography.pxToRem(290),
    borderBottom: `1px solid ${ColorPalette.BlueGrayExtraDark}`,
  },
}));

interface Props {
  readonly children: ReactNode;
}

export function AppBar({ children }: Props) {
  const classes = useStyles();

  const isSmall = useMediaQuery<any>((theme) => theme.breakpoints.down("sm"));

  return (
    <MUIAppBar position="fixed" classes={{ root: classes.appBar }}>
      {!isSmall && (
        <Toolbar classes={{ root: classes.titleToolbar }}>
          <DotIcon className={classes.dot} size={20} />
          <Typography classes={{ root: classes.title }} variant="h5" noWrap={true}>
            Midlo
          </Typography>
        </Toolbar>
      )}

      <Toolbar classes={{ root: classes.actionToolbar }}>
        {isSmall && <AppMenuButtonWrapper />}

        <div className={classes.actionsContainer}>{children}</div>

        <AppUserMenuWrapper />
      </Toolbar>
    </MUIAppBar>
  );
}
