import React from "react";
import { Typography } from "@material-ui/core";

import { AppLayout } from "../components/app/AppLayout";

export function NotFoundPage() {
  return (
    <AppLayout>
      <Typography align="center" variant="h6">
        Page not found
      </Typography>
    </AppLayout>
  );
}
